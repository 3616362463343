function loadCSS() {
  console.log('css')
  import("../sass/7-bundles/index.scss");
}

function loadBrain() {
  // console.log('brain loaded')
  import('../brain/brain.js')
}

function loadNav() {
  // console.log('nav loaded')
  import('../scripts/nav.js')
}

function loadPreloader() {
  import('../scripts/preloader.js')
}

function loadCirugias() {
  import('../scripts/cirugias.js')
}

window.addEventListener('load', async function loadModules() {
  await loadPreloader()
  await loadCSS()
  await loadBrain()
  await loadNav()
  await loadCirugias()
})